export const Arabic = {
  father: "الأب",
  mother: "الأم",
  civilian_boys: "أولاد مدنيون",
  divorce_document: "وثيقة الطلاق",
  custody_decision: "قرار الحضانة", 
  husband_death_certificate: "شهادة وفاة الزوج",
  marriage_certificate: "شهادة زواج",
  widow: "أرملة",
  divorced: "مطلقة",
  head_of_household: "رب الأسرة",
  family_card_request: "طلب بطاقة عائلية",
  accept: "قبول",
  reject: "رفض",
  pending: 'معلق',
  expire_date: 'تاريخ انتهاء الصلاحية',
  att_alert: "يرجي ادخال صورتين",
  from_front: "من الأمام",
  from_back: "من الخلف",
  freeze: "تجميد",
  profits: "الأرباح",
  year: 'سنه',
  choose_a_year_of_offer: "اختيار سنه العرض",
  peopleOfDeterminationOffers: "عروض ذو الهمم",
  dashboard: "الصفحه الرئيسيه",
  admins: "المدراء",
  admin: "المشرف",
  rules: "صلاحيات",
  users: "المستخدمين",
  categories: "التصنيفات",
  category: "التصنيف",
  sub_categories: "التصنيفات الفرعيه",
  sub_category: "التصنيف الفرعي",
  brands: "العلامات التجارية",
  variant: "اضافات",
  dynamic_variant: "اضافات بسعر",
  products: "المنتجات",
  product: "المنتج",
  digital_products: "المنتجات الرقمية",
  reports: "التقارير",
  notification: "الاشعارات",
  time_slot: "اوقات العمل",
  banners: "لافتات",
  website_banners: "لافتات الموقع",
  members: "أعضاء",
  ad_screen: "اعلانات",
  job: "وظيفه",
  jobs: "وظائف",
  setting: "اعدادات",
  service_offers: "عروض خدميه",
  social_offers: "عروض اجتماعيه",
  delivery: "توصيل",
  social_media: "وسائل التواصل",
  social: "وسائل التواصل",
  currency: "العمله",
  payment: "الدفع",
  pages: "الصفحات",
  total_categories: "إجمالي التصنيفات",
  total_sub_categories: "إجمالي التصنيفات",
  total_brands: "إجمالي العلامات التجارية",
  total_admins: "إجمالي المدراء",
  gmail: "بريد إلكتروني",
  total_activities_and_events: "إجمالي الأنشطة والفعاليات",
  total_board_of_directors: "إجمالي مجلس الإداره",
  total_branches_and_markets: "إجمالي الفروع والأسواق",
  total_news: "إجمالي الأخبار",
  total_offers: "إجمالي العروض",
  total_users: "إجمالي المستخدمين",
  on_the_way: "في الطريق",
  canceled: "ألغيت",
  delivered: "تم التوصيل",
  shipped: "تم الشحن",
  process: "يتم المعالجه",
  ordered: "تم الطلب",
  total_sales: "إجمالي المبيعات",
  sales: "المبيعات",
  daily: "يومي",
  add_admin: "إضافة مشرف",
  add_admins: "إضافة مشرفين",
  name: "الإسم",
  first_name: "الإسم الاول",
  last_name: "الإسم الآخير",
  email: "بريد إلكتروني",
  address: "الموقع",
  address_link: "رابط الموقع",
  control: "التحكم",
  diwans: "الدواوين",
  shareholders: "المساهمين",
  shareholders_requests: "طلبات المساهمين",
  attachments: "مرفقات",
  number_of_people: "عدد الافراد",
  membership_number: "رقم العضويه",
  item_no: "رقم الصنف",
  barcode: "باركود",
  product_name: "اسم المنتج",
  import: "استيراد",
  phone: "رقم التليفون",
  password: "الرقم السري",
  country_code: "كود الدوله",
  permissions: "الأذونات",
  status: "حالة",
  details: 'تفاصيل',
  rule: "إذن",
  full_permissions: "أذونات كاملة",
  civil_id: "الرقم المدني",
  box_number: "رقم الصندوق",
  family_card: "بطاقة العائلة",
  shareholder_code_number: "كود رقم المساهم",
  some_permissions: "بعض الأذونات",
  no_permissions: "لا أذونات",
  news: "الأخبار",
  new: "خبر",
  works_hours: "ساعات العمل",
  read_only: "قراءه فقط",
  edit: "تعديل",
  delete: "مسح",
  search_by: "البحث عن طريق",
  profile: "الحساب الشخصي",
  logout: "تسجيل الخروج",
  cancel: "إلغاء",
  submit: "إضافه",
  add: "إضافه",
  select: "اختار",
  deleted: "تم الحذف",
  next: "التالي",
  previous: "السابق",
  go_to_page: "الذهاب الي صفحه",
  page: "صفحه",
  of: "من",
  add_categories: "إضافة تصنيف",
  active: "نشط",
  not_active: "غير نشط",
  image: "الصوره",
  remove_image: "تم مسح الصوره",
  deactive: "تعطيل",
  english: "اللغه الانجليزيه",
  arabic: "اللغه العربيه",
  category_image: "صوره التصنيف",
  close: " اغلاق",
  price: "السعر",
  in_stock: "متوفره",
  english_title: "العنوان باللغة الانجليزية",
  english_name: "الإسم باللغة الانجليزية",
  arabic_title: "العنوان باللغة العربية",
  arabic_name: "الإسم باللغة العربية",
  english_description: "الوصف باللغة الإنجليزية",
  arabic_description: "الوصف باللغة العربية",
  description: "الوصف",
  activities: "الأنشطه",
  activity: "نشاط",
  branches_and_markets: "الفروع والأسواق",
  code: "كود المنتج",
  cost: "التكلفه",
  quantity: "كمية",
  weight: "وزن",
  offer_price: "سعر العرض",
  best_seller: "الأكثر مبيعًا",
  new_in: "الجديد في",
  offer: "عرض",
  offers: "العروض",
  contact_us: "تواصل معنا",
  talabat: "طلبات",
  images: "الصور",
  create: "إنشاء",
  you_tube: "يوتيوب",
  types_by_english: "أنواع باللغة الإنجليزية",
  types_by_arabic: "أنواع باللغة العربية",
  add_new_value: "إضافة قيمة جديدة",
  chosse: "اختيار",
  has_amount: "لديه كميه",
  amount: "الكميه",
  available_amount: "الكميه المتاحه",
  payment_method: "طريقه الدفع",
  total_price: "السعر الكلي",
  sub_price: "السعر الجزئي",
  total: "الكلي",
  customer_name: "إسم العميل",
  exist_user: "عميل موجود",
  new_user: "عميل جديد",
  search: "بحث",
  order_day: "يوم التوصيل",
  from: "من",
  to: "إلي",
  coupon: "كود الخصم",
  verified: "تم التحقق",
  updated_successfully: "تم التحديث بنجاح",
  updated_status_successfully: "تم تحديث الحالة بنجاح",
  profile_updated_successfully: "تم تحديث الملف الشخصي بنجاح",
  banners_updated_successfully: "تم تحديث اللافتات بنجاح",
  banners_added_successfully: "تم إضافه اللافتات بنجاح",
  apply: "تطبيق",
  enter: "أدخل",
  add_new_product: "إضافه منتج جديد",
  date_range: "المده",
  export: "تصدير",
  visa: "فيزا",
  cash: "كاش",
  type: "النوع",
  end_date: "تاريخ الإنتهاء",
  start_date: "تاريخ البدأ",
  max_usage: "الاستخدام الأقصى",
  count_usage: "عدد الإستخدام",
  percentage: "نسبة مئوية",
  fixed: "نسبة ثابته",
  net_profit: "صافي الربح",
  or: "أو",
  serial_number: "الرقم التسلسلي",
  serial_image: "الصورة التسلسلية",
  title: "العنوان",
  message: "الرساله",
  send: "إرسال",
  delete_message:
    "يرجى ملاحظة أنه لا يمكن التراجع عن هذا الإجراء وسيتم حذف التفاصيل.",
  welcome_back: "مرحبا بعودتك",
  sign_text: "قم بتسجيل الدخول عن طريق إدخال المعلومات أدناه",
  text: "أعمال جيدة جدا في انتظاركم",
  sign_in: "تسجيل الدخول",
  login: "تسجيل الدخول",
  specific_block: "يوم العطله",
  capacity: "سعه",
  interval: "فاصلة",
  open: "فتح",
  rate: "معدل",
  country_name: "اسم الدولة",
  country: "الدولة",
  banner: "لافته",
  link: "رابط",
  ad: "إعلان",
  add_new_screen: "إضافه شاشه أخري",
  website_title: "عنوان موقع الويب",
  website_logo: "شعار موقع الويب",
  mobile_logo: "شعار الجوال",
  dashboard_logo: "شعار لوحة المعلومات",
  color: "اللون",
  label_color: "لون النصوص",
  possible_delivery: "امكانيه التوصيل",
  cash_in_delivery_fees: "رسوم تسليم النقد",
  shipping_fee: "رسوم الشحن",
  price_per_kg: "السعر للكيلو جرام",
  type_of_area: "نوع المنطقة",
  all_area: "لكل المناطق",
  per_area: "للمنطقة",
  delivery_fees: "رسوم التوصيل",
  facebook: "فيسبوك",
  snapchat: "سناب شات",
  instagram: "إنستغرام",
  whatsapp: "واتس اب",
  twitter: "تويتر",
  linked_in: "لينكد إن",
  telegram: "تيليجرام",
  tiktok: "تيك توك",
  app_store: "متجل أبل",
  play_store: "متجر جوجل",
  call_us: "اتصل بنا",
  iban: "رقم الآيبان",
  about: "حول",
  faqs: "الأسئلة الشائعة",
  privacy: "الخصوصية",
  address_name: "العنوان",
  block: "كتلة",
  street: "الشارع",
  area: "المنطقة",
  first: "أولا",
  avenue: "الجادة",
  building_number: "رقم المبنى",
  floor_number: "رقم الطابق",
  office_number: "رقم المكتب",
  house_number: "رقم المنزل",
  apt_number: "رقم الشقة",
  other_instructions: "تعليمات أخرى",
  delivery_day: "يوم التوصيل",
  time: "الوقت",
  confirm_added: "تأكيد الإضافة",
  added: "إضافة",
  confirm_des: "يمكنك إضافة منتج آخر عن طريق تغيير بعض المعلومات",
  add_more_products: "أضف المزيد من نفس المنتج",
  reference_id: "الرقم المرجعي",
  invoice_id: "رقم الفاتورة",
  post_date: "التاريخ",
  createdAt: "أنشئت في",
  order_id: "رقم الطلب",
  static_pages: "الصفحات الثابته",
  field_required: "هذا الحقل مطلوب",
  ordered_successfully: "تم الطلب بنجاح",
  successfully: "بنجاح",
  ordered_failed: "فشل الطلب",
  failed_msg: "عمليه الدفع لم تكتمل",
  back_home: "العودة إلى الصفحة الرئيسية",
  knet: "كي نت",
  bidding_management: "إدارة عروض الأسعار",
  app_commission_management: "إدارة عمولة التطبيق",
  wallet_management: "إدارة المحفظة",
  brand_management: "إدارة العلامة التجارية",
  time_management: "إدارة الوقت",
  location: "الموقع",
  website: "موقع إلكتروني",
};
